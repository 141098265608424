.pagevoirplusecole{
    background-color: rgb(76, 76, 76);
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
}
.page-contentvoirplusecole{
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: white;
    flex-direction: column;
    width: 80%;
}