.arc-background {
    background-color: white;
    /*min-height: 256vh;*/
    padding-bottom: 2vh; /* Add some padding to the bottom */
}

.espaceheader {
    height: 18vh;
}

.nosservicesheader {
    font-size: 50px;
    height: 12vh;
    background-color: rgb(255, 255, 255);
    color: black;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 1vh;
    font-weight: bold;
    text-transform: uppercase;
    letter-spacing: 2px;
}

.pagesecurite {
    /*height: 61vh;*/
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 3vh; /* Add margin to the bottom */
    /*background-color: #f0f0f0;*/
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    overflow: hidden; /* Ajout de cette ligne pour éviter le débordement */
}

.divsecu {
    background-image: url('../../images/pexels-cottonbro-4065876.jpg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    display: flex;
    flex-direction: column;
    justify-content: center; /* Center the content vertically */
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    position: relative;
    overflow: hidden;
    height: 60vh;
}

.pagesecuriteinterieur {
    height: 95%;
    width: 99.2%;
}

.titresecurite {
    height: 5vh;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 40px;
    color: #000000;
    transition: opacity 0.3s ease-in-out;
}

.descriptionsecurite {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    
    color: #555;
    line-height: 1.6;
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
    background-color: rgba(255, 255, 255, 0.717);
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 20px;
    border-radius: 10px;
    flex-direction: column;
}

.descriptionsecurite ul {
    list-style-type: none;
    padding: 0;
}

.descriptionsecurite ul li {
    margin-bottom: 10px;
}

.secu {
    width: 20%;
    height: 5vh;
    display: flex;
    align-items: center;
    justify-content: center;
    border: solid 1px black;
    border-radius: 20px;
    background-color: white;
}

.pagenouveauxservices {
    height: auto; /* Change height to auto to fit content */
}

.ligne {
    display: flex;
    justify-content: space-around;
    align-items: center;
    height: 70vh;
    margin-bottom: 3vh; /* Add margin to the bottom */
}

.service {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    overflow: hidden;
    height: 100%;
    width: 49%;
    border-radius: 10px;
    padding: 20px;
    background-size: cover;
    background-position: center;
    background-image: inherit; /* Utiliser l'image de fond définie dans le style */
    transition: background-color 0.3s ease-in-out; /* Transitions pour les changements de couleur de fond */
}

/* Gérer le titre */
.grostitre {
    color: white; /* Ajustez selon vos besoins */
    font-size: 40px; /* Ajustez selon vos besoins */
    z-index: 2; /* Assurez-vous que le titre est au-dessus des autres éléments */
    transition: opacity 0.3s ease-in-out; /* Transition pour le changement de visibilité */
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 10px 20px;
    border-radius: 5px;
    /*-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);*/
}

/* Gestion de la superposition pour l'effet d'opacité */
.service::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0.702); /* Superposition blanche semi-transparente */
    opacity: 0; /* Opacité normale par défaut */
    transition: opacity 0.3s ease-in-out;
    z-index: 1; /* Assurer que c'est au-dessus de l'image mais en dessous du texte */
}

/* Augmenter l'opacité de la superposition au survol */
.service:hover::after {
    opacity: 1;
}

/* Masquer le titre et afficher le texte de description au survol */
.service:hover .grostitre {
    opacity: 0; /* Masquer le titre lors du survol */
}

.service .fondtexte {
    /*background-color: rgba(255, 255, 255, 0.862);*/
    padding: 10px;
    border-radius: 10px;
    height: 80%;
    z-index: 2; /* Assurer que le texte est au-dessus de la superposition */
    opacity: 0; /* Texte caché par défaut */
    transition: opacity 0.3s ease-in-out;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

/* Montrer la description au survol */
.service:hover .fondtexte {
    opacity: 1;
}

.service .titreligne {
    font-size: 30px;
    padding-bottom: 20px;
}

.service .descriptionligne {
    font-size: 22px;
    width: 30vw;
    text-align: justify;
    
}
.descriptionligne {
    margin-bottom: 7vh;
}
.titrelignesec {
    font-size: 25px;
}
/* Effet de survol pour la div sécurité */
.divsecu:hover .titresecurite {
    opacity: 0;
}

.divsecu:hover .descriptionsecurite {
    opacity: 1;
}

@media (max-width: 300px) {
    .service .titreligne, .titrelignesec {
        font-size: 7px;
    }
    .service .descriptionligne, .descriptionsecurite {
        font-size: 3px;
    }
}

@media (max-width: 500px) {
    .service .titreligne, .titrelignesec {
        font-size: 10px;
    }
    .service .descriptionligne, .descriptionsecurite {
        font-size: 5px;
    }
}

@media (max-width: 800px) {
    .service .titreligne, .titrelignesec {
        font-size: 15px;
    }
    .service .descriptionligne, .descriptionsecurite {
        font-size: 10px;
    }
}

@media (max-width: 1000px) {
    .service .titreligne, .titrelignesec {
        font-size: 20px;
    }
    .service .descriptionligne, .descriptionsecurite {
        font-size: 15px;
    }
}
@media (min-width:1800 ){
    .service .titreligne, .titrelignesec {
        font-size: 220px;
    }
    .service .descriptionligne, .descriptionsecurite {
        font-size: 180px;
    }
}




@media (min-width: 1800px) {
    .service .titreligne, .titrelignesec {
        font-size: 25px;
    }
    .service .descriptionligne, .descriptionsecurite {
        font-size: 20px;
    }
}

@media (max-height: 400px) {
    .service .descriptionligne, .descriptionsecurite {
        display: none;
    }
}

@media (max-width: 300px) {
    .ligne {
        flex-wrap: wrap;
    }
}

@media (min-aspect-ratio: 0/1) and (max-aspect-ratio: 1/1) {
    .service {
        width: 98%;
        margin: 1vh;
    }
    .service .descriptionligne {
        width: 60vw;
    }
    .ligne {
        flex-direction: column;
        margin-bottom: 0vh;
        height: 120vh;
    }
}

/* Initialement, les images sont transparentes */
.service img {
    opacity: 0;
    transition: opacity 0.5s ease-in-out; /* Transition de 0.5s pour un effet de fondu */
}

/* Classe pour les images visibles */
.service.visible img {
    opacity: 1;
}
/* services.css */
.fade-in {
    opacity: 0;
    animation: fadeIn 3s forwards;
  }
  
  @keyframes fadeIn {
    to {
      opacity: 1;
    }
  }
.en-savoir-plus {
    background-color: rgb(21, 83, 197);
    text-decoration: none;
    color: rgb(255, 255, 255);
    padding: 10px 15px 10px 15px;
    margin-top: 3vh;
    transition: all 0.4s ;
    border: solid 1px  rgb(21, 83, 197);
    font-size: 15px;
    border-radius: 20px;
}
.en-savoir-plus:hover {
    background-color: rgb(1, 32, 144);
    color: rgb(255, 255, 255);
    border: solid 1px black;
    border-radius: 20px;
}