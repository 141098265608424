/* Footer.css */
.footer {
    background-color: #000000;
    height: 200px;
    text-align: center;
    color: #fff;
    bottom: 0;
    width: 100%;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    /*border-radius: 1000px 1000px 0% 0%;*/
   
}

.footer-text {
    margin: 0;
    font-size: 16px;
}

.footer-link {
    color: #1e90ff;
    text-decoration: none;
}

.footer-link:hover {
    text-decoration: underline;
}
@media (max-width: 448px) { /*enlever le 2024 du footer*/
    .demifoot{
        display: block;
    }
}
.reseaux {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 200px;
}
.reseau {
    display: flex;
    justify-content: center;
    align-items: center;
}
.titrereseau{
    padding-bottom: 10px;
}
.logoreseau{
    width: 40px;
}
.descreseau {
    width: 100px;
}
.lien{
    text-decoration: none;
    color: white;
}
@media (max-width:500px) {
    .footer {
        font-size: 10px;
    }
    .lien {
        font-size: 10px;
    }
  }