.pageinfoecole {
    height: 100vh;
    display: flex;
    align-items: center;
    flex-direction: column;

    max-height: 80vh; /* Définir la hauteur maximale à 80% de la hauteur de la fenêtre */
    overflow: auto; /* Afficher la barre de défilement lorsque le contenu dépasse la hauteur maximale */
  }
.forminfoecole {
    overflow-y: auto;
    height: 40vh;
    display: flex;
    align-items: center;
    justify-content: center;
}
.tableinfoecole {
    width: 80%;
    max-height: 300px; /* Ajustez cette valeur selon vos besoins */
    overflow-y: auto;
    border: 1px solid #ddd; 
}
.inputforminfoecole{
    background-color: rgb(246, 246, 246);
    border: solid 1px grey;
    /*border-radius: 10px;*/
    padding: 5px;
    width: 15vw;
}
.boutonchercher{
    border: none;
    padding: 6px;
}