.article-page {
    background-color: #f4f4f4;
    min-height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
}

.fenetrearticle {
    background-color: #fff;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 80%;
    margin: 15vh 0;
}

.article-image {
    border-radius: 20px 20px 0 0;
    width: 100%;
    height: 35vh;
}

.article-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 80%;
    padding: 20px 0;
}

.article-date, .article-author {
    color: #a0a0a0;
    font-size: 0.9rem;
}

.article-header h1 {
    font-size: 2rem;
    margin: 0;
    text-align: center;
    flex: 1;
}

.textearticle {
    background-color: #fff;
    width: 80%;
    text-align: justify;
    margin-bottom: 50px;
    font-size: 1rem;
    line-height: 1.6;
}
