/* src/components/divAnimee.css */
.animated-div {
    opacity: 0;
    transform: translateY(30%);
    transition: opacity 1s ease-out, transform 1s ease-out;
}

.animated-div.show {
    opacity: 1;
    transform: translateY(0);
}
