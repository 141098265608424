.pageadmin {
    height: 100vh;
    font-size: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
.pageadmin {
    padding: 20px;
}

table {

    border-collapse: collapse;
}
.tableauecole {
    width: 80%;
    overflow-y: auto;
    border: 1px solid #ddd;
    height: 60%;
}

th, td {
    border: 1px solid #ddd;
    padding: 8px;
}

th {
    background-color: #f2f2f2;
    text-align: center;
}
.caseactions {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
}
.fondaction {
    border-radius: 50%;
    padding: 10px;
}
.fondeye {
    background-color: rgb(195, 195, 200);
}
.fondbin {
    background-color: rgb(255, 160, 160);
}
.fondplus {
    background-color: rgb(157, 232, 255);
}
button:hover {
    background-color: red;
}