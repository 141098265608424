/* Header.css */
/* header.css */

.burger, .croix {
    cursor: pointer;
    padding: 10px;
}

.burger {
    display: none;
    z-index: 5;
    padding: 10px;
    margin-right: 30px;
}

.croix {
    display: none;
    z-index: 5;
    padding: 12px;
    margin-right: 30px;
}
.numdecontact{
    display: none;
}

.header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: transparent;
    padding: 10px 10px;
    color: rgb(0, 0, 0);
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 4;
    transition: background-color 0.3s ease;

}

.header.scrolled {
    background-color: #ffffff4d;
    color: #000000;
    backdrop-filter: blur(10px); /* Ajoutez l'effet de flou */
}

/* ... (le reste de votre CSS) */

.header-logo img {
    height: 50px;
    width: 50px;
}

.header-title {
    font-size: 25px;
    margin: 0;
    display: none;
}

.header-nav {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    /*margin-right: 40px;*/
}

.header-nav ul {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    gap: 15px;
}

.header-nav a {
    color: #000000;
    text-decoration: none;
    font-size: 25px;
    border-radius: 4px;
    padding: 2px 4px;
    transition: background-color 0.3s ease, color 0.3s ease;
}
/*changer la couleur de l'écriture au defilement*/
.header-nav a.scrolled {
    color: #000000;
}
.header-nav a:hover {
    /*background-color: #c2c7caa1;*/
    color: rgb(179, 179, 179);
}

.logo {
    width: 220px;

}

@media (max-width: 1100px) {
    .header-nav a {
        font-size: 20px;
        padding: 1px 1px;
    }
    .header-title {
        font-size: 20px;
        padding: 1px 1px;
    }
}
@media (max-width: 470px) {
    .header-title{
        display: none;
    }
    .header{
        padding: 10px 20px;
    }
    
}
@media (max-width: 750px) {
    .header-nav {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        height: 100vh;
        background-color: white;
        opacity: 90%;
        flex-direction: column;
        align-items: center;
        padding: 70px 10px 10px 10px; /* Ajustez le padding selon vos besoins */
        transform: translateY(-100%);
        transition: transform 0.3s ease;
        box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
        z-index: 4;
        overflow-y: auto; /* Permet le défilement vertical si nécessaire */
        display: flex;
        justify-content: left;
        align-items: start;
        padding-left: 50px;
        font-weight: bold;
    }

    .header-nav.open {
        transform: translateY(0);
    }

    .header-nav ul {
        flex-direction: column;
        gap: 10px;
        width: 100%;
        align-items: baseline; /* Assurez-vous que cette propriété est correctement définie */
    }

    .header-nav a {
        display: block;
        width: 100%;
        padding: 10px; /* Ajustez le padding selon vos besoins */
        font-size: 18px; /* Ajustez la taille de la police selon vos besoins */
        text-align: center;
        font-size: 25px; /* Assurez-vous de ne pas redéfinir la taille de la police deux fois */
    }

    .burger {
        display: block;
    }

    .croix {
        display: none;
    }

    .header-nav.open ~ .burger {
        display: none;
    }

    .header-nav.open ~ .croix {
        display: block;
    }
    .numdecontact{
        display: block;
        padding-top: 30px;
        
        font-size: 20px;

    }
}
@media (max-height: 400px) { /* quand sur telephone et en format paysage, la navbar n'est plus tjr en haut*/
    .header {
        position:relative;
        height: 100px;
    }
    .logo {
        width: 170px;
    }
}