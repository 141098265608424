.pagecontact {
  min-height: 100vh;
  margin: 0 auto;
  padding: 20px;
  font-family: Arial, sans-serif;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative; /* Ajoutez cette ligne pour permettre le positionnement absolu du pseudo-élément */
}

.pagecontact::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-image: url("./../../images/fond-gris.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  opacity: 0.5; /* Ajustez cette valeur pour changer l'opacité */
  z-index: -1; /* Assurez-vous que le pseudo-élément est en dessous du contenu */
}

  .pagedanspagecontact {
    margin: 10vh 0 5vh 0;
    padding: 25px 0 25px 0;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0);
    border-radius: 8px;
    background-color: rgb(255, 255, 255);
    /*background-image: url("./../../images/fond-gris.png");
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;*/
    width: 70vw;
    display: flex;
    align-items: center;
    justify-content: center;
}
.interieurpagecontact {
    width: 80%;
    max-width: 1000px;
}
.interieurpagecontact h1 {
    text-align: center;
    margin-bottom: 20px;
  }
  .interieurpagecontact textarea {
    max-height: 30vh;
  }
  .contact-form {
    display: flex;
    flex-direction: column;
    
  }
  
  .form-group {
    margin-bottom: 15px;
  }
  
  .form-group label {
    display: block;
    margin-bottom: 5px;
    font-weight: bold;
  }
  
  .form-group input,
  .form-group textarea {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 16px;
    border: none;
    background-color: rgb(228, 228, 228);
  }
  
  .form-group textarea {
    resize: vertical;
    height: 100px;
  }
  
  .submit-button {
    background-color: #007BFF;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .submit-button:hover {
    background-color: #0056b3;
  }
  
  .submit-button:focus {
    outline: none;
  }
  
@media (max-width:500px) {
    .pagedanspagecontact {
        width: 90vw;
    }
    .interieurpagecontact {
        width: 90%;
    }
}
.colonnegauche{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.colonnedroite {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.colonnedroite input {
  width: 27vw;
}
.colonnegauche input {
  width: 27vw;
}

@media (max-width: 500px) {
  .colonnedroite {
    flex-direction: column;
  }
  .colonnedroite input {
    width: 100%;
  }
  .colonnegauche {
    flex-direction: column;
  }
  .colonnegauche input {
    width: 100%;
  }
}