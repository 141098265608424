/* pageadmin.css */
.pageadmin {
    height: 100vh;
    font-size: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 20px;
}

.table-container {
    width: 80%;
    max-height: 300px; /* Ajustez cette valeur selon vos besoins */
    overflow-y: auto;
    border: 1px solid #ddd; /* Ajoutez une bordure pour le conteneur pour plus de clarté */
}

table {
    width: 100%;
    border-collapse: collapse;
}

th, td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: center;
}

th {
    background-color: #f2f2f2;
     position: sticky;
     top: 0;
     z-index: 0;
}

.boutonsupprimeruser{
    background-color: red;
}
.boutonsupprimeruser:hover {
    background-color: rgb(192, 0, 0);
}
.colonneactionuser {
    display: flex;
    justify-content: space-around;
    align-items: center;
}