/* src/components/tableau.css */

.service-list {
    display: flex;
    flex-direction: column;
    gap: 15px; /* Espacement entre chaque service */
    padding: 20px;
}

.service-item-container {
    width: 85vw;
}

.service-item {
    padding: 20px;
    background-color: #f9f9f9;
    border: 1px solid #ddd;
    border-radius: 5px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    display: flex;
    justify-content: space-between;
    align-items: center;
    transition: background-color 0.3s;
}

.service-item:hover {
    background-color: #ddd;
}

.service-title {
    font-weight: bold;
    font-size: 14px;
}

.info-link {
    color: #425cdd;
    text-decoration: none;
    padding: 5px 10px;
    border: 1px solid #425cdd;
    border-radius: 3px;
    transition: all 0.3s;
    background: none;
    cursor: pointer;
}

.info-link:hover {
    background-color: #425cdd;
    color: white;
}

.service-description {
    padding: 20px;
    background-color: #f1f1f1;
    border: 1px solid #ccc;
    border-radius: 5px;
    margin-top: 10px;
    animation: fadeIn 0.3s;
}

.description-content {
    font-size: 14px;
}

/* Animation for fade in effect */
@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}
