.pagemap{
    min-height: 100vh;
    display: flex;
    background-color: rgb(255, 200, 200);
}
.fenetremap{
    min-height: 100vh;
    width: 50vw;
    display: flex;
    align-items: center;
    justify-content: center;
    border: solid 1px rgb(255, 247, 0);
}
.leaflet-container {
    width: 40vw;
    border: solid 1px rgb(0, 0, 0);
}
.mapcolonedroite {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50vw;
}