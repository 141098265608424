/* src/blog.css */
body {
    font-family: Arial, sans-serif;
    background-color: #f4f4f4;
    margin: 0;
    padding: 0;
}

.pageblogg {
    min-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 20px;
    background-color: #fff;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    width: 80%;
    max-width: 800px;
    margin: 20vh auto;
}

h1 {
    color: #333;
    margin-bottom: 20px;
}

.search-bar {
    padding: 10px;
    width: 100%;
    margin-bottom: 20px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 16px;
}

.article-list {
    list-style-type: none;
    padding: 0;
    width: 100%;
}

.articleunite {
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 4px;
    margin-bottom: 20px;
    padding: 20px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.articleunite h2 {
    margin-top: 0;
    color: #333;
}

.article-content {
    color: #666;
    margin-bottom: 10px;
}

.article-meta {
    display: flex;
    justify-content: space-between;
    color: #999;
    font-size: 14px;
}

.article-image {
    width: 100%;
    height: 200px;
    object-fit: cover;
    display: block;
    margin-bottom: 20px; /* Ajustez selon vos besoins */
}
  
@media (max-width:500px) {
    .pageblogg {
        width: 90%;
    }
    
    .articleunite {
        padding: 10px;

    }
    .pageblogg {
        padding: 10px;
    }
}