.carousel {
  position: relative;
  width: 100%;
  overflow: hidden;
  height: 100vh; /* Assurez-vous que le carrousel couvre toute la hauteur de l'écran */
}

.carousel-inner {
  display: flex;
  transition: transform 0.5s ease-in-out;
  height: 100vh; /* Assurez-vous que le conteneur interne couvre toute la hauteur de l'écran */
}

.carousel-item {
  width: 100%;
  height: 100vh; /* Assurez-vous que chaque élément du carrousel couvre toute la hauteur de l'écran */
  box-sizing: border-box;
  position: relative;
}

.carousel-item img {
  width: 100vw;
  height: 100vh; /* Assurez-vous que l'image couvre toute la hauteur de l'écran */
  object-fit: cover; /* Assurez-vous que l'image ne soit pas déformée */
  background-size: cover;
  background-repeat: no-repeat;
}

.carousel-caption {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
  background: rgba(0, 0, 0, 0.5);
  padding: 20px;
  border-radius: 10px;
  min-height: 200px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.carousel-caption h2 {
  margin-bottom: 20px;
  font-size: 40px;
}

@media (max-width: 800px) {
  .carousel-caption h2 {
    font-size: 30px;
  }
}
@media (max-width: 450px) {
  .carousel-caption h2 {
    font-size: 20px;
  }
}

.btn-primarycar {
  background-color: #007bff;
  border: none;
  border-radius: 25px;
  color: white;
  padding: 10px 20px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 10px 2px;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.3s ease;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.btn-primarycar:hover {
  background-color: #0056b3;
  transform: translateY(-2px);
}

.left-arrow,
.right-arrow {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  font-size: 24px;
  background: rgba(0, 0, 0, 0.5);
  color: white;
  border: none;
  padding: 10px;
  cursor: pointer;
}

.left-arrow {
  left: 10px;
}

.right-arrow {
  right: 10px;
}
