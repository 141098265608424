.pagefaq {
    height:  100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 50px;
    flex-direction: column;
}
.titrefaqclient {
    
    margin-top: 100px;
}