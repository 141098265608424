.App {
    text-align: left;
}
.pageformulaire {
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #f4f4f4;
} 
.formulairelogin {
    background-color: white;
    border: solid 1px black;
    padding: 20px;
    height: 400px;
    width: 40vw;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
.titreform {
    font-size: 35px;
    font-weight: bold;
    background-color: rgb(207, 207, 207);
    padding: 25px 0px 30px 0px;
    width: 250px;
}
.form {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 300px;
    width: 300px;
    background-color: rgb(255, 255, 255);
}
.btn {
    border: solid 1px blue;
    background-color: white;
    text-decoration: none;
    font-size: 25px;
    padding: 10px;
}
.btn-success {
    background-color: green;
}
.btnlogin {
    width: 80%;
    height: 40px;
    font-size: 25px;
    background-color: rgb(111, 229, 111);
    border: none;
    transition: 0.2s;
}
.btnlogin:hover {
    background-color: rgb(202, 255, 202);
}
.informations {
    border: solid 1px black;
    font-size: 20px;
    height: 15vh;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: space-around;
}
.inputlogin{
    font-size: 20px;
    height: 30px;
    width: 80%;
    background-color: rgb(238, 238, 238);
    border: none;
}
.inputlogin {
    margin: 20px;
}
.phrase {
    height: 10vh;
    display: flex;
    justify-content: center;
    align-items: center;
}
.messageerreur {
    color: red;
}
@media (max-width: 700px) {
    .formulaire {
        width: 280px;
        height: 400px;
    }
    .mb-4 {
        width: 280px;
    }
    .inputlogin {
        font-size: 15px;
  
    }
}
@media (max-width: 700px) {
    .formulaire {
        width: 280px;
        height: 400px;
    }
    .mb-4 {
        width: 280px;
    }
    .inputlogin {
        font-size: 15px;
    }
}