/* infoprofs.css */

.grandepageinfoecole{
    height: 100vh;
}

.pageinfoecole {
    display: flex;
    align-items: center;
    flex-direction: column;
  }
  

  
  .inputforminfoecole {
    background-color: rgb(246, 246, 246);
    border: solid 1px grey;
    padding: 5px;
    width: 15vw;
  }
  
  .boutonchercher {
    border: none;
    padding: 6px;
  }
  
  .table-container {
    min-height: 30vh;
    max-height: 60vh; /* Définir la hauteur maximale à 60% de la hauteur de la fenêtre */
    overflow: auto; /* Afficher la barre de défilement lorsque le contenu dépasse la hauteur maximale */
    display: flex;
    flex-direction: column;
    align-items: center;
    border: none;
}

  
  .enteteformprofs{
    position: sticky; /* Garde l'en-tête du tableau fixe en haut */
    top: 0;
    background-color: white; /* Couleur de fond de l'en-tête du tableau */
    z-index: 0; 
  }
  th,
  td {
    word-wrap: break-word; /* Permettre le retour à la ligne dans les cellules */
  }
  