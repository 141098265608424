.pageentreprise {
    min-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #6c6c6c;
}
.pageblancheentreprise{
    background-color: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 80%;
    min-width: 80%;
    padding: 50px;
    margin-top: 15vh;
    margin-bottom: 10vh;
}
.tableauorganismes {
    overflow-y: auto;
}
.details {
    background-color: #fff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .details h1,
  .details h2 {
    margin-bottom: 10px;
  }
  
  .details p {
    margin: 5px 0;
  }
  
  .details h3 {
    margin-top: 20px;
    margin-bottom: 10px;
  }
  
  .details ul {
    list-style-type: none;
    padding: 0;
  }
  
  .details ul li {
    background-color: #f9f9f9;
    margin: 5px 0;
    padding: 10px;
    border-radius: 4px;
  }
  .boutonsupprimer {
    background-color: rgb(255, 39, 39);
  }
  .boutonsupprimer:hover {
    background-color: rgb(203, 0, 0);
  }