.pageclasse {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 20px;
    background-color: rgb(74, 74, 74);
    height: 100vh;
} 
.pageblanche {
    margin-top: 50px;
    background-color: white;
    height: 75vh;
    width:80%;
}
.classeform {
    flex-direction: column;
    display: flex;
    height: 50vh;
    justify-content: center;
    align-items: center;
}
.partieform {
    display: flex;
    justify-content: space-around;
    align-items: center;
}
.formgauche {
    margin-left: 10px;
    display: flex;
    flex-direction: column;
}
.classelist {
 
    height: 50vh;
    display: flex;
    justify-content: center;
    align-items: center;
}
.tableauclasse{
    height: 100%;
    width: 100%;
}
.boutonsuivant {
    background-color: rgb(136, 223, 131);
    font-size: 20px;
    margin: 20px 0px 20px 0px;
    padding: 15px;
    transition: 0.2s;
    border: none;
    width: 600px;

}
.boutonsuivant:hover {
    background-color: rgb(211, 255, 203);
    
}
.hauttableau {
    position: sticky;
}
.slidebar {
    width: 50vh;
    height: 40vh;
    overflow-y: auto;
      
}
.iconepoubelle {
    background-color: rgb(255, 165, 165);
    border: none;
    border-radius: 50px;
    padding: 12px;
}
.iconepoubelle:hover {
    background-color: rgb(255, 120, 120);
}