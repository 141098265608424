.pageerreur {
    background-color: rgb(255, 255, 255);
    height: 100vh;
    font-size: 30px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    
}
.phrase {
    padding: 30px;
    padding-bottom: 80px;
}
/*
.boutonretour {
    text-decoration: none;
    color : rgb(3, 21, 213);
    border: solid 2px blue;
    transition: 0.2s ;
    padding: 10px;
    margin-bottom: 100px;
}
.boutonretour:hover {
    background-color: rgb(46, 105, 255);
    padding: 10px;
     color: white;
}*/
.licorne {
    background-image: url("../../images/Pink-Invisible-Unicorn.png");
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: end;
    padding-bottom: 30px;
}