.pageprof {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 20px;
    background-color: rgb(74, 74, 74);
    height: 100vh;
  }
  
  .pageblancheprof{
    margin-top: 50px;
    background-color: white;
    height: 75vh;
  }
  .profform {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex-grow: 1; /* Occupe tout l'espace disponible */
  }
  .partieformprof {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .titreprof {
    font-size: 30px;
  }
  @media (max-width: 900px) {
    .partieformprof  {
        flex-direction: column;
    }
    .formgaucheprof {
        margin-left: 10vw;
        display: flex;
        flex-direction: column;
    }
    .tableauprof {
        height: 80%;
        width: 80%;
    }
    .pageprof {
        height: 150vh;
    }
    .pageblancheprof {
        padding-top: 10vh;
        background-color: white;
        height: 150vh;
    }
    
  }
  .intclasslist{
  
    overflow-y: auto; /* Ajoute une barre de défilement verticale si nécessaire */
    max-width: 100%;
    max-height : 30vh; 
  }

  .boutonajouter {
    background-color: rgb(130, 218, 130);
    margin-top: 15px;
    padding: 5px;
    border: none;
    width: 100%;
  }
  .proflist {
    height: 50vh;
    display: flex;
    justify-content: center;
    align-items: center;
 /* Ajoute une barre de défilement verticale si nécessaire */
  }

  .table-containerprof{
   /* Ajoute une barre de défilement verticale si nécessaire */
    min-height: 60vh; /* Hauteur minimale de la zone contenant le tableau */
    max-height: 100vh; /* Hauteur maximale de la zone contenant le tableau */
  }
  
  .tableauprof {
    height: 80%;
    width: 80%;
    z-index: 0;
  }
  
  .tableauprof thead,
  .tableauprof tbody {
    display: block; /* Permet de définir une hauteur fixe pour l'en-tête et le corps du tableau */
  }
  
  .tableauprof tbody {
    overflow-y: auto; /* Ajoute une barre de défilement verticale si nécessaire */
    height: calc(100% - 30px); /* Hauteur du corps du tableau = hauteur totale - hauteur de l'en-tête */
  }
  
  .tableauprof tr {
    width: 100%;
    display: table;
    table-layout: fixed;
  }
  
  .tableauprof th,
  .tableauprof td {
    display: table-cell;
  }
  
  .table-headerprof {
    position: sticky; /* Garde l'en-tête du tableau fixe en haut */
    top: 0;
    background-color: white; /* Couleur de fond de l'en-tête du tableau */
    z-index: 1; /* Permet de superposer l'en-tête du tableau au-dessus du corps du tableau */
  }
  .boutonsuivantprof {
    background-color: rgb(136, 223, 131);
    font-size: 20px;
    margin: 20px 0px 20px 0px;
    padding: 15px;
    transition: 0.2s;
    border: none;
    width: 600px;

}
.boutonsuivantprof:hover {
    background-color: rgb(211, 255, 203);
    
}
.formgaucheprof {
    margin-left: 10vw;
}
.poubelleprof {
    background-color: rgb(255, 171, 171);
    border: none;
    border-radius: 50px;
    padding: 10px;
}
.poubelleprof:hover {
  background-color: rgb(255, 197, 197);
}