.pagementionlegale {
    min-height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
}
.mentions-container {
    padding: 20vh 0 20vh 0;
    max-width: 80vw;
    margin: 0 auto;
    font-family: Arial, sans-serif;
    text-align: left;
    text-align: justify;
  }
  
  .mentions-title {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 10vh;
  }
  
  .mentions-paragraph {
    margin-bottom: 15px;
    line-height: 1.6;
  }
  @media (max-width:500px) {
    .mentions-container {
        font-size: 12px;
        
    }
  }