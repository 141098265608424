.textareafaq {
    resize: none;
}
.pagefaqadmin{
    min-height:  100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size:20px;
    flex-direction: column;
    padding: 20px;
}
  
.faq-list {
    margin-top: 20px;
    min-height: 60vh;
}
  
.faq-item {
    margin-bottom: 10px;
    border: solid 1px black;
}
  
.faq-question {
    cursor: pointer;
    padding: 10px;
    background-color: #f0f0f0;
    border: 1px solid #ccc;
    display: flex;
    justify-content: space-between;
    width: 600px;
    height: 50px;
}
  
.faq-answer {
    padding: 10px;
    background-color: #e0e0e0;
    border: 1px solid #ccc;
    margin-top: 5px;
    display: none;
    width: 600px;
}
  
.faq-answer.active {
    display: block;
}
  
.formfaqadmin {
    border: solid 1px black;
    padding: 30px;
    margin-top: 100px;
}
.formfaq {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
   
}
